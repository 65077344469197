import { Engine } from '../engine';
import React from "react"
import { Movement, SystemDataQueryResult, SystemDataQuery, Tag } from "../generated/graphql";

type SystemMovement = Pick<Movement, "id" | "name"> & { tags: Pick<Tag, "id"> }

export interface SystemContextState {
    loading: boolean;
    error?: any;
    movements: SystemDataQuery["movements"];
    tagCategories: SystemDataQuery["tagCategories"];
    tags: SystemDataQuery["tags"];
    volumes: SystemDataQuery["volumes"];
    intensities: SystemDataQuery["intensities"];
    engine?: Engine;
    tagMap?: Map<string, SystemDataQuery["tags"][0]>;
    movementMap?: Map<string, SystemDataQuery["movements"][0]>;
}

export const DEFAULT_CONTEXT: SystemContextState = {
    loading: false,
    movements: [],
    tagCategories: [],
    tags: [],
    volumes: [],
    intensities: [],
    tagMap: new Map(),
    movementMap: new Map(),
}

const context = React.createContext(DEFAULT_CONTEXT);

export default context;