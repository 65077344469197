import React from "react";
import { makeStyles, Grid, Slider, withStyles, Divider, Typography, Button, Link } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { ExperienceLevel } from "../api";
import TemplateList from "../components/TemplateList";
import { Link as RouterLink, routes } from "../routing";
import { Add, AddBox } from "@material-ui/icons";
import TemplateFilter from "../components/TemplateFilter";

const useHomeScreenStyles = makeStyles(({ spacing, palette, typography }) => ({
    formLabel: {
        padding: spacing(4, 0, 2, 0)
    },
    slider: {
        width: "90%",
        marginLeft: "5%"
    },
}))


interface HomeScreenProps {

}


interface FormData {
    experienceLevel: ExperienceLevel;
    // focus: FocusArea;
}

const HomeScreen: React.SFC<HomeScreenProps> = (props) => {
    const classes = useHomeScreenStyles();
    const { control } = useForm<FormData>();
    return (
        <Grid container>
            <TemplateFilter />
        </Grid>
    );
};

export default HomeScreen;