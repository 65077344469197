import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core";
import { useParams } from "../../routing";
import TemplateEditor from "../../components/TemplateEditor";
import { useGetTemplateQuery, TemplateInput } from "../../generated/graphql";
import Loader from "../../components/Loader";

const useEditTemplateStyles = makeStyles(({ spacing, palette, typography }) => ({

}))

interface TemplateEditProps {

}


const TemplateEdit: React.FC<TemplateEditProps> = (props) => {
    const classes = useEditTemplateStyles();
    const { id } = useParams<{ id: string }>();
    const { data, loading, error } = useGetTemplateQuery({ variables: { id } });

    const templateInput: TemplateInput | undefined = useMemo(() => {
        if (data?.template) {
            return data?.template as TemplateInput;
        } else {
            return undefined;
        }
    }, [data]);

    return (
        <Loader isLoading={loading}>
            {data && (
                <TemplateEditor template={templateInput} />
            )}
        </Loader>
    );
};

export default TemplateEdit;