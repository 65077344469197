import React from "react";
import { makeStyles } from "@material-ui/core";
import { TemplateInput } from "../../generated/graphql";

import WorkoutGeneratorContextProvider from "./WorkoutGeneratorContextProvider";
import WorkoutGeneratorLayout from "./components/Layout";


interface WorkoutGeneratorProps {
    template?: TemplateInput;
    experienceLevel?: string;
}

const WorkoutGenerator: React.FC<WorkoutGeneratorProps> = (props) => {
    const { template, experienceLevel } = props;

    return (
        <WorkoutGeneratorContextProvider template={template} experienceLevel={experienceLevel}>
            <WorkoutGeneratorLayout />
        </WorkoutGeneratorContextProvider>
    );
};

export default WorkoutGenerator;