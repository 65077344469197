import React from "react";
import { makeStyles } from "@material-ui/core";
import TemplateEditor from "../../components/TemplateEditor";

const useNewTemplateStyles = makeStyles(({spacing, palette, typography})=>({

}))

interface NewTemplateProps {

}

const NewTemplate : React.FC<NewTemplateProps> = (props) => {
    const classes = useNewTemplateStyles();
    return (
        <TemplateEditor />
    );
};

export default NewTemplate;