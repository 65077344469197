export const GRAPHQL_API_URL = process.env.REACT_APP_BOUNDLESS_API_URL;
export const ENV: "development" | "production" = process.env.NODE_ENV as any;


/**
 * Returns true react is in development mode
 *
 * @export
 * @returns
 */
export function isDevelopment() {
    return ENV === "development";
}