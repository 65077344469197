import React, { useCallback, useMemo } from "react";
import { makeStyles } from "@material-ui/core";
import { useMovementFilterTagCategoriesQuery } from "../../generated/graphql";
import Loader from "../Loader";
import TagChooser from "../TagChooser";

const useMovementFilterTagChooserStyles = makeStyles(({ spacing, palette, typography }) => ({

}))

interface MovementFilterTagChooserProps {
    selectedTagIds?: string[];
    onChange?: (tagIds: string[]) => void;
}

const MovementFilterTagChooser: React.FC<MovementFilterTagChooserProps> = (props) => {
    const classes = useMovementFilterTagChooserStyles();
    const { data, loading, error } = useMovementFilterTagCategoriesQuery();
    const { selectedTagIds = [], onChange } = props;

    const categoryIds = useMemo(() => {
        return data?.tagCategories.map(tc => tc.id) || [];
    }, [data])

    return (
        <Loader isLoading={loading}>
            {data && (
                <TagChooser
                    categoryIds={categoryIds}
                    onChange={onChange}
                    selectedTagIds={selectedTagIds}
                    label="Movement Tags"
                />
            )}
        </Loader>
    );
};

export default MovementFilterTagChooser;