import React from "react";
import { Divider, Grid, IconButton, makeStyles, Typography } from "@material-ui/core";
import TemplateEditor from "../../components/TemplateEditor";
import { AddBox } from "@material-ui/icons";
import { routes, Link } from "../../routing";
import TemplateList from "../../components/TemplateList";

const useTemplateListScreenStyles = makeStyles(({ spacing, palette, typography }) => ({
    divider: {
        margin: spacing(2, 0,4,0)
    }
}))

interface TemplateListScreenProps {

}

const TemplateListScreen: React.FC<TemplateListScreenProps> = (props) => {
    const classes = useTemplateListScreenStyles();
    return (
        <Grid container direction="column">
            <Grid item container direction="row" justify="space-between">
                <Typography variant="h5">Templates</Typography>
                <IconButton component={Link} to={routes.template.new} edge="start" color="inherit" aria-label="Add Template">
                    <AddBox fontSize="large" />
                </IconButton>
                {/* <Link component={RouterLink} to={routes.template.new}>
                    <AddBox fontSize="large" />
                </Link> */}
            </Grid>
            {/* <InputLabel className={classes.formLabel}>Area of Focus</InputLabel>
        <Controller
            name="focus"
            control={control}
            render={({ onChange, value }) => (
                <Toggle
                    onChange={onChange}
                    value={value}
                    options={FocusAreas.map((value) => ({ value }))}
                />
            )}
        /> */}

            <Divider className={classes.divider} />
            <Grid>
                <Grid item xs={12}>
                    <TemplateList />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default TemplateListScreen;