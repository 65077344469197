import { TagCategory } from './../../functions/graphql/graphql';
export const CATEGORY_EXPERIENCE_LEVEL = "Experience Level";
export const CATEGORY_SERIES_STRUCTURE = "Series Structure";

export enum TAG_CATEGORY {
    "Experience Level" = "Experience Level",
    "Assessment" = "Assessment",
    "Hand Position" = "Hand Position",
    "Stimulus" = "Stimulus",
    "ROM" = "ROM",
    "Series Structure" = "Series Structure",
    "Intensity" = "Intensity",
    "Movement Direction" = "Movement Direction",
    "Movement Sequence" = "Movement Sequence",
    "Movement Pattern" = "Movement Pattern",
    "Tempo" = "Tempo",
    "Prime Mover" = "Prime Mover",
    "Anterior/Posterior" = "Anterior/Posterior",
    "Mobility" = "Mobility",
    "Load Position" = "Load Position",
    "Movement Implement" = "Movement Implement",
    "Classification" = "Classification",
    "Movement Setup" = "Movement Setup",
    "Activated Muscle Group" = "Activated Muscle Group",
    "Grip" = "Grip",
    "Upper/Lower" = "Upper/Lower",
}