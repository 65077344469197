import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Movement = {
  __typename?: 'Movement';
  id: Scalars['String'];
  name: Scalars['String'];
  parentMovement: Array<Movement>;
  tags: Array<Tag>;
  tagIds: Array<Scalars['String']>;
};

export type Tag = {
  __typename?: 'Tag';
  id: Scalars['String'];
  name: Scalars['String'];
  category: Array<TagCategory>;
  tagId: Scalars['Int'];
};

export type TagCategory = {
  __typename?: 'TagCategory';
  id: Scalars['String'];
  name: Scalars['String'];
  tags: Array<Tag>;
  tagIds: Array<Scalars['String']>;
  metaTags: Array<Tag>;
  metaTagIds: Array<Scalars['String']>;
};

export type Volume = {
  __typename?: 'Volume';
  id: Scalars['String'];
  tags: Array<Tag>;
  tagIds: Array<Scalars['String']>;
  sets: Array<Scalars['Int']>;
  reps: Array<Scalars['Int']>;
};

export type Intensity = {
  __typename?: 'Intensity';
  id: Scalars['String'];
  tags: Array<Tag>;
  tagIds: Array<Scalars['String']>;
  intensity: Scalars['Float'];
  repsLow: Scalars['Int'];
  repsHigh: Scalars['Int'];
  volumeOptimal: Scalars['Int'];
  volumeLow: Scalars['Int'];
  volumeHigh: Scalars['Int'];
};

export enum MovementSeriesType {
  Regular = 'REGULAR',
  Superset = 'SUPERSET'
}

export type MovementFilter = {
  __typename?: 'MovementFilter';
  id: Scalars['String'];
  tags: Array<Tag>;
  tagIds: Array<Scalars['String']>;
};

export type MovementSeries = {
  __typename?: 'MovementSeries';
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  type: MovementSeriesType;
  movements: Array<MovementFilter>;
  tags: Array<Tag>;
  tagIds: Array<Scalars['String']>;
};

export type Template = {
  __typename?: 'Template';
  id: Scalars['String'];
  tags: Array<Tag>;
  tagIds: Array<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  seriesCollection: Array<MovementSeries>;
};

export type TemplateInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  tagIds: Array<Scalars['String']>;
  seriesCollection: Array<MovementSeriesInput>;
};

export type MovementSeriesInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  type: MovementSeriesType;
  movements: Array<MovementFilterInput>;
  tagIds: Array<Scalars['String']>;
};

export type MovementFilterInput = {
  id: Scalars['String'];
  tagIds: Array<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  tags: Array<Tag>;
  tagCategories: Array<TagCategory>;
  movements: Array<Movement>;
  volumes: Array<Volume>;
  intensities: Array<Intensity>;
  templates: Array<Template>;
  template: Template;
};


export type QueryTagsArgs = {
  filter?: Maybe<Scalars['String']>;
};


export type QueryTagCategoriesArgs = {
  filter?: Maybe<Scalars['String']>;
};


export type QueryMovementsArgs = {
  filter?: Maybe<Scalars['String']>;
};


export type QueryVolumesArgs = {
  filter?: Maybe<Scalars['String']>;
};


export type QueryIntensitiesArgs = {
  filter?: Maybe<Scalars['String']>;
};


export type QueryTemplatesArgs = {
  filter?: Maybe<Scalars['String']>;
};


export type QueryTemplateArgs = {
  id: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  sayHello: Scalars['String'];
  saveTemplate: Template;
};


export type MutationSayHelloArgs = {
  input?: Maybe<Scalars['String']>;
};


export type MutationSaveTemplateArgs = {
  input: TemplateInput;
};

export type ExperienceTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type ExperienceTagsQuery = { __typename?: 'Query', experience: Array<{ __typename?: 'TagCategory', id: string, levels: Array<{ __typename?: 'Tag', id: string, name: string }> }> };

export type MovementFilterTagCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type MovementFilterTagCategoriesQuery = { __typename?: 'Query', tagCategories: Array<{ __typename?: 'TagCategory', id: string }> };

export type MovementListQueryVariables = Exact<{ [key: string]: never; }>;


export type MovementListQuery = { __typename?: 'Query', movements: Array<{ __typename?: 'Movement', id: string, name: string, tags: Array<{ __typename?: 'Tag', id: string, name: string }> }> };

export type SeriesTagCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type SeriesTagCategoriesQuery = { __typename?: 'Query', tagCategories: Array<{ __typename?: 'TagCategory', id: string }> };

export type TagChooserTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type TagChooserTagsQuery = { __typename?: 'Query', tagCategories: Array<{ __typename?: 'TagCategory', id: string, name: string, tags: Array<{ __typename?: 'Tag', id: string, name: string }> }> };

export type TemplateFieldsFragment = { __typename?: 'Template', id: string, name: string, description?: Maybe<string>, tagIds: Array<string>, seriesCollection: Array<{ __typename?: 'MovementSeries', id?: Maybe<string>, name: string, type: MovementSeriesType, tagIds: Array<string>, movements: Array<{ __typename?: 'MovementFilter', id: string, tagIds: Array<string> }> }> };

export type SaveTemplateMutationVariables = Exact<{
  template: TemplateInput;
}>;


export type SaveTemplateMutation = { __typename?: 'Mutation', saveTemplate: (
    { __typename?: 'Template' }
    & TemplateFieldsFragment
  ) };

export type TemplateFilterQueryVariables = Exact<{ [key: string]: never; }>;


export type TemplateFilterQuery = { __typename?: 'Query', templates: Array<(
    { __typename?: 'Template', tags: Array<{ __typename?: 'Tag', id: string, name: string, category: Array<{ __typename?: 'TagCategory', id: string, name: string }> }> }
    & TemplateFieldsFragment
  )> };

export type TemplateListQueryVariables = Exact<{ [key: string]: never; }>;


export type TemplateListQuery = { __typename?: 'Query', templates: Array<{ __typename?: 'Template', id: string, name: string, description?: Maybe<string> }> };

export type TemplateTagCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type TemplateTagCategoriesQuery = { __typename?: 'Query', tagCategories: Array<{ __typename?: 'TagCategory', id: string }> };

export type GetTemplateQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetTemplateQuery = { __typename?: 'Query', template: (
    { __typename?: 'Template' }
    & TemplateFieldsFragment
  ) };

export type SystemDataQueryVariables = Exact<{ [key: string]: never; }>;


export type SystemDataQuery = { __typename?: 'Query', movements: Array<{ __typename?: 'Movement', id: string, name: string, tagIds: Array<string> }>, tags: Array<{ __typename?: 'Tag', id: string, name: string }>, tagCategories: Array<{ __typename?: 'TagCategory', id: string, name: string, tagIds: Array<string>, metaTagIds: Array<string> }>, volumes: Array<{ __typename?: 'Volume', id: string, sets: Array<number>, reps: Array<number>, tagIds: Array<string> }>, intensities: Array<{ __typename?: 'Intensity', id: string, tagIds: Array<string>, intensity: number, repsLow: number, repsHigh: number, volumeOptimal: number, volumeLow: number, volumeHigh: number }> };

export const TemplateFieldsFragmentDoc = gql`
    fragment TemplateFields on Template {
  id
  name
  description
  tagIds
  seriesCollection {
    id
    name
    type
    tagIds
    movements {
      id
      tagIds
    }
  }
}
    `;
export const ExperienceTagsDocument = gql`
    query ExperienceTags {
  experience: tagCategories(filter: "SEARCH('Experience Level',Name) != ''") {
    id
    levels: tags {
      id
      name
    }
  }
}
    `;

/**
 * __useExperienceTagsQuery__
 *
 * To run a query within a React component, call `useExperienceTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExperienceTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExperienceTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useExperienceTagsQuery(baseOptions?: Apollo.QueryHookOptions<ExperienceTagsQuery, ExperienceTagsQueryVariables>) {
        return Apollo.useQuery<ExperienceTagsQuery, ExperienceTagsQueryVariables>(ExperienceTagsDocument, baseOptions);
      }
export function useExperienceTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExperienceTagsQuery, ExperienceTagsQueryVariables>) {
          return Apollo.useLazyQuery<ExperienceTagsQuery, ExperienceTagsQueryVariables>(ExperienceTagsDocument, baseOptions);
        }
export type ExperienceTagsQueryHookResult = ReturnType<typeof useExperienceTagsQuery>;
export type ExperienceTagsLazyQueryHookResult = ReturnType<typeof useExperienceTagsLazyQuery>;
export type ExperienceTagsQueryResult = Apollo.QueryResult<ExperienceTagsQuery, ExperienceTagsQueryVariables>;
export const MovementFilterTagCategoriesDocument = gql`
    query MovementFilterTagCategories {
  tagCategories(filter: "SEARCH('Movement Filter',Tags) != ''") {
    id
  }
}
    `;

/**
 * __useMovementFilterTagCategoriesQuery__
 *
 * To run a query within a React component, call `useMovementFilterTagCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMovementFilterTagCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMovementFilterTagCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMovementFilterTagCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<MovementFilterTagCategoriesQuery, MovementFilterTagCategoriesQueryVariables>) {
        return Apollo.useQuery<MovementFilterTagCategoriesQuery, MovementFilterTagCategoriesQueryVariables>(MovementFilterTagCategoriesDocument, baseOptions);
      }
export function useMovementFilterTagCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MovementFilterTagCategoriesQuery, MovementFilterTagCategoriesQueryVariables>) {
          return Apollo.useLazyQuery<MovementFilterTagCategoriesQuery, MovementFilterTagCategoriesQueryVariables>(MovementFilterTagCategoriesDocument, baseOptions);
        }
export type MovementFilterTagCategoriesQueryHookResult = ReturnType<typeof useMovementFilterTagCategoriesQuery>;
export type MovementFilterTagCategoriesLazyQueryHookResult = ReturnType<typeof useMovementFilterTagCategoriesLazyQuery>;
export type MovementFilterTagCategoriesQueryResult = Apollo.QueryResult<MovementFilterTagCategoriesQuery, MovementFilterTagCategoriesQueryVariables>;
export const MovementListDocument = gql`
    query MovementList {
  movements {
    id
    name
    tags {
      id
      name
    }
  }
}
    `;

/**
 * __useMovementListQuery__
 *
 * To run a query within a React component, call `useMovementListQuery` and pass it any options that fit your needs.
 * When your component renders, `useMovementListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMovementListQuery({
 *   variables: {
 *   },
 * });
 */
export function useMovementListQuery(baseOptions?: Apollo.QueryHookOptions<MovementListQuery, MovementListQueryVariables>) {
        return Apollo.useQuery<MovementListQuery, MovementListQueryVariables>(MovementListDocument, baseOptions);
      }
export function useMovementListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MovementListQuery, MovementListQueryVariables>) {
          return Apollo.useLazyQuery<MovementListQuery, MovementListQueryVariables>(MovementListDocument, baseOptions);
        }
export type MovementListQueryHookResult = ReturnType<typeof useMovementListQuery>;
export type MovementListLazyQueryHookResult = ReturnType<typeof useMovementListLazyQuery>;
export type MovementListQueryResult = Apollo.QueryResult<MovementListQuery, MovementListQueryVariables>;
export const SeriesTagCategoriesDocument = gql`
    query SeriesTagCategories {
  tagCategories(filter: "SEARCH('Series Tag',Tags) != ''") {
    id
  }
}
    `;

/**
 * __useSeriesTagCategoriesQuery__
 *
 * To run a query within a React component, call `useSeriesTagCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSeriesTagCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSeriesTagCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSeriesTagCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<SeriesTagCategoriesQuery, SeriesTagCategoriesQueryVariables>) {
        return Apollo.useQuery<SeriesTagCategoriesQuery, SeriesTagCategoriesQueryVariables>(SeriesTagCategoriesDocument, baseOptions);
      }
export function useSeriesTagCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SeriesTagCategoriesQuery, SeriesTagCategoriesQueryVariables>) {
          return Apollo.useLazyQuery<SeriesTagCategoriesQuery, SeriesTagCategoriesQueryVariables>(SeriesTagCategoriesDocument, baseOptions);
        }
export type SeriesTagCategoriesQueryHookResult = ReturnType<typeof useSeriesTagCategoriesQuery>;
export type SeriesTagCategoriesLazyQueryHookResult = ReturnType<typeof useSeriesTagCategoriesLazyQuery>;
export type SeriesTagCategoriesQueryResult = Apollo.QueryResult<SeriesTagCategoriesQuery, SeriesTagCategoriesQueryVariables>;
export const TagChooserTagsDocument = gql`
    query TagChooserTags {
  tagCategories {
    id
    name
    tags {
      id
      name
    }
  }
}
    `;

/**
 * __useTagChooserTagsQuery__
 *
 * To run a query within a React component, call `useTagChooserTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagChooserTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagChooserTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTagChooserTagsQuery(baseOptions?: Apollo.QueryHookOptions<TagChooserTagsQuery, TagChooserTagsQueryVariables>) {
        return Apollo.useQuery<TagChooserTagsQuery, TagChooserTagsQueryVariables>(TagChooserTagsDocument, baseOptions);
      }
export function useTagChooserTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TagChooserTagsQuery, TagChooserTagsQueryVariables>) {
          return Apollo.useLazyQuery<TagChooserTagsQuery, TagChooserTagsQueryVariables>(TagChooserTagsDocument, baseOptions);
        }
export type TagChooserTagsQueryHookResult = ReturnType<typeof useTagChooserTagsQuery>;
export type TagChooserTagsLazyQueryHookResult = ReturnType<typeof useTagChooserTagsLazyQuery>;
export type TagChooserTagsQueryResult = Apollo.QueryResult<TagChooserTagsQuery, TagChooserTagsQueryVariables>;
export const SaveTemplateDocument = gql`
    mutation saveTemplate($template: TemplateInput!) {
  saveTemplate(input: $template) {
    ...TemplateFields
  }
}
    ${TemplateFieldsFragmentDoc}`;
export type SaveTemplateMutationFn = Apollo.MutationFunction<SaveTemplateMutation, SaveTemplateMutationVariables>;

/**
 * __useSaveTemplateMutation__
 *
 * To run a mutation, you first call `useSaveTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveTemplateMutation, { data, loading, error }] = useSaveTemplateMutation({
 *   variables: {
 *      template: // value for 'template'
 *   },
 * });
 */
export function useSaveTemplateMutation(baseOptions?: Apollo.MutationHookOptions<SaveTemplateMutation, SaveTemplateMutationVariables>) {
        return Apollo.useMutation<SaveTemplateMutation, SaveTemplateMutationVariables>(SaveTemplateDocument, baseOptions);
      }
export type SaveTemplateMutationHookResult = ReturnType<typeof useSaveTemplateMutation>;
export type SaveTemplateMutationResult = Apollo.MutationResult<SaveTemplateMutation>;
export type SaveTemplateMutationOptions = Apollo.BaseMutationOptions<SaveTemplateMutation, SaveTemplateMutationVariables>;
export const TemplateFilterDocument = gql`
    query TemplateFilter {
  templates {
    ...TemplateFields
    tags {
      id
      name
      category {
        id
        name
      }
    }
  }
}
    ${TemplateFieldsFragmentDoc}`;

/**
 * __useTemplateFilterQuery__
 *
 * To run a query within a React component, call `useTemplateFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateFilterQuery({
 *   variables: {
 *   },
 * });
 */
export function useTemplateFilterQuery(baseOptions?: Apollo.QueryHookOptions<TemplateFilterQuery, TemplateFilterQueryVariables>) {
        return Apollo.useQuery<TemplateFilterQuery, TemplateFilterQueryVariables>(TemplateFilterDocument, baseOptions);
      }
export function useTemplateFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TemplateFilterQuery, TemplateFilterQueryVariables>) {
          return Apollo.useLazyQuery<TemplateFilterQuery, TemplateFilterQueryVariables>(TemplateFilterDocument, baseOptions);
        }
export type TemplateFilterQueryHookResult = ReturnType<typeof useTemplateFilterQuery>;
export type TemplateFilterLazyQueryHookResult = ReturnType<typeof useTemplateFilterLazyQuery>;
export type TemplateFilterQueryResult = Apollo.QueryResult<TemplateFilterQuery, TemplateFilterQueryVariables>;
export const TemplateListDocument = gql`
    query TemplateList {
  templates {
    id
    name
    description
  }
}
    `;

/**
 * __useTemplateListQuery__
 *
 * To run a query within a React component, call `useTemplateListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateListQuery({
 *   variables: {
 *   },
 * });
 */
export function useTemplateListQuery(baseOptions?: Apollo.QueryHookOptions<TemplateListQuery, TemplateListQueryVariables>) {
        return Apollo.useQuery<TemplateListQuery, TemplateListQueryVariables>(TemplateListDocument, baseOptions);
      }
export function useTemplateListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TemplateListQuery, TemplateListQueryVariables>) {
          return Apollo.useLazyQuery<TemplateListQuery, TemplateListQueryVariables>(TemplateListDocument, baseOptions);
        }
export type TemplateListQueryHookResult = ReturnType<typeof useTemplateListQuery>;
export type TemplateListLazyQueryHookResult = ReturnType<typeof useTemplateListLazyQuery>;
export type TemplateListQueryResult = Apollo.QueryResult<TemplateListQuery, TemplateListQueryVariables>;
export const TemplateTagCategoriesDocument = gql`
    query TemplateTagCategories {
  tagCategories(filter: "SEARCH('Template Tag',Tags) != ''") {
    id
  }
}
    `;

/**
 * __useTemplateTagCategoriesQuery__
 *
 * To run a query within a React component, call `useTemplateTagCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateTagCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateTagCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTemplateTagCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<TemplateTagCategoriesQuery, TemplateTagCategoriesQueryVariables>) {
        return Apollo.useQuery<TemplateTagCategoriesQuery, TemplateTagCategoriesQueryVariables>(TemplateTagCategoriesDocument, baseOptions);
      }
export function useTemplateTagCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TemplateTagCategoriesQuery, TemplateTagCategoriesQueryVariables>) {
          return Apollo.useLazyQuery<TemplateTagCategoriesQuery, TemplateTagCategoriesQueryVariables>(TemplateTagCategoriesDocument, baseOptions);
        }
export type TemplateTagCategoriesQueryHookResult = ReturnType<typeof useTemplateTagCategoriesQuery>;
export type TemplateTagCategoriesLazyQueryHookResult = ReturnType<typeof useTemplateTagCategoriesLazyQuery>;
export type TemplateTagCategoriesQueryResult = Apollo.QueryResult<TemplateTagCategoriesQuery, TemplateTagCategoriesQueryVariables>;
export const GetTemplateDocument = gql`
    query getTemplate($id: String!) {
  template(id: $id) {
    ...TemplateFields
  }
}
    ${TemplateFieldsFragmentDoc}`;

/**
 * __useGetTemplateQuery__
 *
 * To run a query within a React component, call `useGetTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTemplateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTemplateQuery(baseOptions?: Apollo.QueryHookOptions<GetTemplateQuery, GetTemplateQueryVariables>) {
        return Apollo.useQuery<GetTemplateQuery, GetTemplateQueryVariables>(GetTemplateDocument, baseOptions);
      }
export function useGetTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTemplateQuery, GetTemplateQueryVariables>) {
          return Apollo.useLazyQuery<GetTemplateQuery, GetTemplateQueryVariables>(GetTemplateDocument, baseOptions);
        }
export type GetTemplateQueryHookResult = ReturnType<typeof useGetTemplateQuery>;
export type GetTemplateLazyQueryHookResult = ReturnType<typeof useGetTemplateLazyQuery>;
export type GetTemplateQueryResult = Apollo.QueryResult<GetTemplateQuery, GetTemplateQueryVariables>;
export const SystemDataDocument = gql`
    query SystemData {
  movements {
    id
    name
    tagIds
  }
  tags {
    id
    name
  }
  tagCategories {
    id
    name
    tagIds
    metaTagIds
  }
  volumes {
    id
    sets
    reps
    tagIds
  }
  intensities {
    id
    tagIds
    intensity
    repsLow
    repsHigh
    volumeOptimal
    volumeLow
    volumeHigh
  }
}
    `;

/**
 * __useSystemDataQuery__
 *
 * To run a query within a React component, call `useSystemDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useSystemDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSystemDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useSystemDataQuery(baseOptions?: Apollo.QueryHookOptions<SystemDataQuery, SystemDataQueryVariables>) {
        return Apollo.useQuery<SystemDataQuery, SystemDataQueryVariables>(SystemDataDocument, baseOptions);
      }
export function useSystemDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SystemDataQuery, SystemDataQueryVariables>) {
          return Apollo.useLazyQuery<SystemDataQuery, SystemDataQueryVariables>(SystemDataDocument, baseOptions);
        }
export type SystemDataQueryHookResult = ReturnType<typeof useSystemDataQuery>;
export type SystemDataLazyQueryHookResult = ReturnType<typeof useSystemDataLazyQuery>;
export type SystemDataQueryResult = Apollo.QueryResult<SystemDataQuery, SystemDataQueryVariables>;