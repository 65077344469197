import React, { useContext } from "react";
import { makeStyles, Button } from "@material-ui/core";
import { TemplateEditorContext } from "../Context";

const useSaveButtonStyles = makeStyles(({ spacing, palette, typography }) => ({

}))

interface SaveButtonProps {

}

const SaveButton: React.FC<SaveButtonProps> = (props) => {
    const classes = useSaveButtonStyles();
    const { saveTemplate } = useContext(TemplateEditorContext);
    return (
        <Button variant="contained" color="primary" onClick={saveTemplate}>Save</Button>
    );
};

export default SaveButton;