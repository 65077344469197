import React from "react"
import { Template } from "../../api/Template";
import { MovementSeries, MovementFilter, MovementSeriesType } from "../../api";
import { TemplateInput, MovementSeriesInput, MovementFilterInput } from "../../generated/graphql";

export interface TemplateEditorContextState {
    template: TemplateInput;
    setName: (name: string) => void;
    setDescription: (description: string) => void;
    setTagIds: (ids: string[]) => void;
    addSeries: (index: number) => void;
    removeSeries: (series: MovementSeriesInput) => void;
    updateSeries: (series: MovementSeriesInput, updates: Partial<MovementSeriesInput>) => void;
    addMovementFilter: (series: MovementSeriesInput, index: number) => void;
    removeMovementFilter: (filter: MovementFilterInput) => void;
    setFilterTags: (filter: MovementFilterInput, tagIds: string[]) => void;
    saveTemplate: () => void;
    isSaving: boolean;
}

const NO_OP = () => { }

const DEFAULT_TEMPLATE: TemplateInput = {
    name: "Untitled Template",
    seriesCollection: [],
    tagIds: []
}

export const DEFAULT_CONTEXT: TemplateEditorContextState = {
    template: DEFAULT_TEMPLATE,
    setName: NO_OP,
    setDescription: NO_OP,
    setTagIds: NO_OP,
    addSeries: NO_OP,
    removeSeries: NO_OP,
    updateSeries: NO_OP,
    addMovementFilter: NO_OP,
    removeMovementFilter: NO_OP,
    setFilterTags: NO_OP,
    saveTemplate: NO_OP,
    isSaving: false
}



export const TemplateEditorContext = React.createContext(DEFAULT_CONTEXT);