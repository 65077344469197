
import React from 'react';
import { ThemeProvider, createMuiTheme, CssBaseline, responsiveFontSizes, fade } from "@material-ui/core";
import "typeface-poppins";
import "typeface-open-sans";
import { Overrides } from "@material-ui/core/styles/overrides";
import { ComponentsProps } from "@material-ui/core/styles/props";

export const CommonColors = {
    primary: "#033d66",
    secondary: "#0d2333",
    secondaryLight: "#32648b",
    action: "#f07218",
    textPrimary: "#0D0601",
    textSecondary: "#19364d",
    background: "#F7F7F7",
    paper: "#FFFFFF",
    lightGrey: "#666666",
    yellow: "#F0BC34",
    green: "#365708"
};

interface ThemeProps {

}

const theme = createMuiTheme({
    props: {
        MuiCheckbox: {
            disableRipple: true
        },
        MuiButtonBase: {
            disableRipple: true
        },
        MuiToggleButton: {
            disableRipple: true
        },
        MuiDialogTitle: {
            disableTypography: true
        }
    } as ComponentsProps,
    overrides: {
        MuiDialogTitle: {
            root: {
                paddingBottom: 4,
                fontWeight: 800,
                fontSize: "1.4em",
                fontFamily: "poppins",
                color: CommonColors.secondary
            }
        },
        MuiDialogContent: {
            root: {
                marginBottom: 12
            }
        },
        MuiDialogActions: {
            root: {
                padding: "4px 12px 12px 12px"
            }
        },
        MuiButton: {
            root: {
                borderRadius: 2
            },
        },
        MuiCheckbox: {
            colorSecondary: {
                color: CommonColors.action,
                "&.Mui-checked": {
                    color: CommonColors.action
                },
                "&.Mui-checked.Mui-disabled": {
                    color: fade(CommonColors.action, 0.50),
                }
            }
        },
        MuiOutlinedInput: {
            notchedOutline: {
                border: "1px solid",
                "& > legend": {
                    maxWidth: 1000
                }
            },
            root: {
                borderRadius: 2,
            }
        },
        MuiFilledInput: {
            root: {
                borderTopLeftRadius: 2,
                borderTopRightRadius: 2,
                backgroundColor: CommonColors.paper,
                "&:hover": {
                    backgroundColor: fade(CommonColors.secondary, 0.05)
                }
            },
        },
        MuiInputLabel: {
            root: {
                fontSize: 16
            }
        },
        MuiToggleButtonGroup: {
            grouped: {
                "&:not(:first-child)": {
                    borderLeft: undefined
                }
            }
        },
        MuiToggleButton: {
            root: {
                background: CommonColors.paper,
                color: CommonColors.primary,
                borderColor: CommonColors.primary,
            },
        },
        MuiListItem: {
            root: {
                textTransform: "capitalize"
            }
        },
        MuiSelect:{
            root:{
                textTransform:"capitalize"
            }
        }
    } as Overrides,
    typography: {
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 700,
        fontFamily: 'Open Sans',
        button: {
            fontFamily: "poppins",
            textTransform: "uppercase",
            letterSpacing: "0.36px"
        },
        body1: {
            fontSize: 18
        },
        body2: {
            fontSize: 16
        },
        caption: {
            fontFamily: "poppins",
            fontSize: 12,
            textTransform: "uppercase"
        },
        h1: {
            fontFamily: "poppins",
            fontSize: 44,
            fontWeight: 700
        },
        h2: {
            fontFamily: "poppins",
            fontSize: 36,
            fontWeight: 700
        },
        h3: {
            fontFamily: "poppins",
            fontSize: 32,
            fontWeight: 700
        },
        h4: {
            fontFamily: "poppins",
            fontSize: 24,
            fontWeight: 700
        },
        h5: {
            fontFamily: "poppins",
            fontSize: 20,
            fontWeight: 700
        },
        h6: {
            fontFamily: "poppins",
            fontSize: 18,
            fontWeight: 900
        },
    },
    palette: {
        primary: {
            main: CommonColors.primary
        },
        secondary: {
            main: CommonColors.secondary,
            light: CommonColors.secondaryLight
        },
        action: {
            active: CommonColors.action
        },
        background: {
            default: CommonColors.background,
            paper: CommonColors.paper
        },

    }
})

const responsiveFontTheme = responsiveFontSizes(theme);

const Theme: React.SFC<ThemeProps> = ({ children }) => {
    return (
        <ThemeProvider theme={responsiveFontTheme}>
            <CssBaseline />
            {children}
        </ThemeProvider>
    );
};

export default Theme;