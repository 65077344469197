import React, { ChangeEvent, useCallback, useContext, useEffect, useMemo } from "react";
import { makeStyles, Slider } from "@material-ui/core";
import Loader from "../Loader";
import { EngineIntensity } from "../../engine/EngineIntensity";
import { SystemContext } from "../../system";
import { TagCollection } from "../../engine/TaggedItem";

const useIntensitySelectorStyles = makeStyles(({ spacing, palette, typography }) => ({

}))

interface IntensitySelectorProps {
    tagIds?: TagCollection
    onChange?: (intensity?: EngineIntensity) => void;
    value?: EngineIntensity;
}

const IntensitySelector: React.FC<IntensitySelectorProps> = (props) => {
    const classes = useIntensitySelectorStyles();
    const { tagIds = [], value, onChange } = props;
    const { loading, engine } = useContext(SystemContext);

    const intensityMap = useMemo(() => {
        const items = engine?.data.findIntensities(tagIds) || [];

        return new Map(
            items.map(item => ([
                item.intensity,
                item
            ]))
        );
    }, [tagIds, engine]);

    const { marks = [], min = 0, max = 0 } = useMemo(() => {
        const values = Array.from(intensityMap.values());
        const intensityValues = values.map(intensity => intensity.intensity);
        return {
            marks: intensityValues.map((intensity) => ({
                value: intensity,
                label: `${(intensity * 100).toFixed(0)} %`
            })),
            min: Math.min(...intensityValues),
            max: Math.max(...intensityValues)
        };

    }, [intensityMap]);

    const handleChange = useCallback((evt: ChangeEvent<{}>, value: number | number[]) => {
        if (onChange && intensityMap) {
            const val = Array.isArray(value) ? value[0] : value;
            const intensity = intensityMap.get(val);
            onChange(intensity);
        }
    }, [onChange, intensityMap])

    const currentValue = useMemo(() => {
        return value?.intensity;
    }, [value]);

    useEffect(() => {
        if (!currentValue && intensityMap && onChange) {
            const intensities = Array.from(intensityMap.values());
            onChange(intensities[0])
        }
    }, [currentValue, onChange, intensityMap]);

    return (
        <Loader isLoading={loading}>
            {marks?.length > 0 && (
                <Slider
                    onChange={handleChange}
                    value={currentValue || min}
                    marks={marks}
                    step={null}
                    min={min}
                    max={max}
                />
            )}
        </Loader>
    );
};

export default IntensitySelector;