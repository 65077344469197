export enum ExperienceLevel{
    Untrained,
    Novice,
    Intermediate,
    Advanced
}

export function displayExperienceLevel(level:number){
    if(level >= ExperienceLevel.Advanced){
        return "advanced";
    }else if (level >= ExperienceLevel.Intermediate){
        return "intermediate";
    }else if (level >= ExperienceLevel.Novice){
        return "novice"
    }else{
        return "untrained";
    }
}