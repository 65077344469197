import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Divider, Grid, makeStyles } from "@material-ui/core";
import { Exercise } from "../../../api";
import IntensitySelector from "../../IntensitySelector";
import { WorkoutGeneratorContext } from "../WorkoutGeneratorContext";
import { SystemDataQuery } from "../../../generated/graphql";
import TagDisplay from "../../TagDisplay";
import TagItem from "../../TagItem";
import { filterNils } from "../../../utils/array";

const useExerciseDisplayStyles = makeStyles(({ spacing, palette, typography }) => ({
    exerciseRow: {
        width: "100%"
    },
}))

interface ExerciseDisplayProps {
    exercise: Exercise;
    seriesTags?: string[];
}


function randomIntBetween(low: number, high: number) {
    return Math.round(Math.random() * (high - low) + low);
}

const ExerciseDisplay: React.FC<ExerciseDisplayProps> = (props) => {
    const classes = useExerciseDisplayStyles();
    const { exercise, seriesTags = [] } = props;
    const { getMovement, workout, generate, experienceLevel, mergeTags } = useContext(WorkoutGeneratorContext);
    const [intensity, setIntensity] = useState<SystemDataQuery["intensities"][0]>();

    const volume = useMemo<{ sets: number, reps: number } | undefined>(() => {
        if (intensity) {
            const { repsHigh, repsLow, volumeHigh, volumeLow, volumeOptimal } = intensity;

            let sets: number;
            let reps: number;
            do {
                const desiredVolume = randomIntBetween(volumeLow, volumeHigh);
                reps = randomIntBetween(repsLow, repsHigh);
                sets = Math.round(desiredVolume / reps);
            } while (sets * reps < volumeLow || sets * reps > volumeHigh)

            return {
                sets,
                reps
            }
        }
    }, [intensity, exercise]);

    const movement = useMemo(() => {
        return exercise.movementId ? getMovement(exercise.movementId) : null;
    }, [exercise]);

    const mergedTags = useMemo(() => {
        const { tagIds: templateTags = [] } = workout || {};
        return mergeTags(templateTags, seriesTags, [experienceLevel]);
    }, [mergeTags, seriesTags, workout, experienceLevel]);


    return (
        <>
            {movement ?
                (
                    <Grid container className={classes.exerciseRow}>
                        <Grid item container xs={12} >
                            <Grid item xs>
                                {movement.name}
                            </Grid>

                            <Grid item xs={2}>
                                {volume && (
                                    <span>
                                        Sets: {volume.sets}
                                    </span>
                                )}
                            </Grid>
                            <Grid item xs={2}>
                                {volume && (
                                    <span>
                                        Reps: {volume.reps}
                                    </span>
                                )}
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <IntensitySelector onChange={setIntensity} value={intensity} tagIds={mergedTags} />
                            {/* {filterNils(mergedTags).map(tag => (
                                <TagItem key={tag} id={tag} />
                            ))} */}
                        </Grid>
                    </Grid>
                )
                : "No Matching Movement"
            }
        </>
    );
};

export default ExerciseDisplay;