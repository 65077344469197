import React, { useContext, useCallback } from "react";
import { makeStyles, Grid, Button, Typography, Card, CardHeader, CardContent } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { MovementSeries } from "../../../api";
import { TemplateEditorContext } from "../Context";
import MovementFilterList from "./MovementFilterList";
import { MovementSeriesInput } from "../../../generated/graphql";
import TextInput from "../../TextInput";
import SeriesTagChooser from "../../SeriesTagChooser";

const useMovmentSeriesItemStyles = makeStyles(({ spacing, palette, typography }) => ({
    container: {
        width: "100%"
    },
    contentContainer: {
        "& > :not(:first-child)": {
            marginTop: spacing(1)
        }
    }
}))

interface MovmentSeriesItemProps {
    series: MovementSeriesInput;
}

const MovmentSeriesItem: React.FC<MovmentSeriesItemProps> = (props) => {
    const classes = useMovmentSeriesItemStyles();
    const { addMovementFilter, removeSeries, updateSeries } = useContext(TemplateEditorContext);
    const { series } = props;

    const remove = useCallback(() => {
        removeSeries(series);
    }, [removeSeries, series]);

    const addMovement = useCallback(() => {
        addMovementFilter(series, series.movements.length);
    }, [addMovementFilter, series]);

    const setName = useCallback((name: string) => {
        updateSeries(series, { name });
    }, [updateSeries, series]);

    const setTags = useCallback((ids: string[]) => {
        updateSeries(series, { tagIds: ids });
    }, [updateSeries, series]);



    const { name, type, movements = [], tagIds } = series;

    return (
        <Card className={classes.container} variant="outlined">
            <CardHeader
                title={<TextInput changeOnBlur label="" value={name} onChange={(txt) => setName(txt)} />}
                // subheader={type}
                action={(
                    <Button onClick={remove}>
                        <Delete />
                    </Button>
                )}
            />
            <CardContent className={classes.contentContainer}>
                <SeriesTagChooser onChange={setTags} selectedTagIds={tagIds} />
                <MovementFilterList movements={movements} />
                <Button variant="contained" color="secondary" onClick={addMovement}>Add movement</Button>
            </CardContent>
        </Card>
    );
};

export default MovmentSeriesItem;