import React, { useState } from "react";
import { makeStyles, Grid, Typography, Paper, Checkbox, InputLabel, Backdrop, CircularProgress } from "@material-ui/core";
import { TemplateEditorContext } from "./Context";
import TemplateEditorContextProvider from "./ContextProvider";
import MovementSeriesList from "./Components/MovementSeriesList";
import WorkoutPreview from "./Components/WorkoutPreview";
import { TemplateInput } from "../../generated/graphql";
import TemplateMetadata from "./Components/TemplateMetadata";
import SaveButton from "./Components/SaveButton";
import ExperienceSelector from "../ExperienceSelector";

const useTemplateEditorStyles = makeStyles(({ spacing, zIndex }) => ({
    title: {
        margin: spacing(1, 0, 3, 0)
    },
    container: {
        padding: spacing(2)
    },
    backdrop: {
        zIndex: zIndex.drawer + 1,
        color: '#fff',
    },
    formLabel: {
        padding: spacing(4, 0, 2, 0)
    },
    experienceSlider: {
        alignSelf: "center"
    }
}));


interface TemplateEditorProps {
    template?: TemplateInput
}

const TemplateEditor: React.FC<TemplateEditorProps> = (props) => {
    const classes = useTemplateEditorStyles();
    const [showJson, setShowJson] = useState(false);
    const [experienceLevel, setExperienceLevel] = useState<string>();
    const { template } = props;
    return (
        <TemplateEditorContextProvider template={template}>
            <Grid container direction="row" spacing={1} justify="space-between">
                <Grid item xs={12} md={7}>
                    <Paper className={classes.container}>
                        <Grid container direction="row" justify="space-between" alignItems="baseline">
                            <Grid xs={10} item container direction="column">
                                <TemplateMetadata />
                            </Grid>
                            <Grid container direction="row" item xs={2} justify="center">
                                <SaveButton />
                            </Grid>
                        </Grid>
                        <MovementSeriesList />
                    </Paper>
                </Grid>

                <Grid item xs={12} md={5}>
                    <Paper className={classes.container}>
                        <Grid className={classes.title} container direction="row" alignItems="center">
                            <Grid item xs={8}>
                                <Typography variant="h4">Workout</Typography>
                            </Grid>
                            <Grid xs={4} item container direction="row" alignItems="center">
                                <InputLabel>Show Json</InputLabel><Checkbox value={showJson} onChange={() => setShowJson((current) => !current)} />
                            </Grid>
                            <Grid container item xs={12} direction="column">
                                <InputLabel className={classes.formLabel}>Experience Level</InputLabel>
                                <ExperienceSelector className={classes.experienceSlider} availableTags={template?.tagIds} onChange={setExperienceLevel} value={experienceLevel} />
                            </Grid>
                        </Grid>
                        <WorkoutPreview experienceLevel={experienceLevel} showJson={showJson} />
                    </Paper>
                </Grid>
                <TemplateEditorContext.Consumer>
                    {({ isSaving }) => (
                        <Backdrop className={classes.backdrop} open={isSaving}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    )}
                </TemplateEditorContext.Consumer>
            </Grid>
        </TemplateEditorContextProvider >
    );
};

export default TemplateEditor;