import React from "react";
import { makeStyles, AppBar, Toolbar, Typography, IconButton, Button, Container } from "@material-ui/core";
import { FitnessCenter, Home, Menu, ViewList } from "@material-ui/icons";
import { NavLink, routes } from "../../routing";



const useHeaderStyles = makeStyles(({ spacing, palette, typography }) => ({
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: spacing(2),
        "&.active": {
            color: palette.action.active
        }
    },
    title: {
        textAlign: "center"
    },
}))

interface HeaderProps {

}

const Header: React.SFC<HeaderProps> = (props) => {
    const classes = useHeaderStyles();
    return (
        <header className={classes.root}>
            <AppBar position="static">
                <Container maxWidth="lg" disableGutters>
                    <Toolbar>
                        <IconButton component={NavLink} exact={true} to={routes.home.path} edge="start" className={classes.menuButton} color="inherit" aria-label="home">
                            <FitnessCenter />
                        </IconButton>
                        <IconButton component={NavLink} to={routes.template.path} edge="start" className={classes.menuButton} color="inherit" aria-label="list">
                            <ViewList />
                        </IconButton>
                        <div className={classes.grow} />
                        <Typography variant="h5" className={classes.title}>
                            Boundless Trainer
                    </Typography>
                        <div className={classes.grow} />
                    </Toolbar>
                </Container>
            </AppBar>
        </header>
    );
};

export default Header;