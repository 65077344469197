import React, { useContext, useCallback } from "react";
import { makeStyles, Grid, Button, Container } from "@material-ui/core";
import { TemplateEditorContext } from "../Context";
import { Delete } from "@material-ui/icons";
import MovementFilterTagChooser from "../../MovementFilterTagChooser";
import { MovementFilterInput } from "../../../generated/graphql";


const useMovementFilterItemStyles = makeStyles(({ spacing, palette, typography }) => ({
    container: {
        border: `1px solid ${palette.divider}`,
        "&:hover": {
            background: palette.grey[100]
        }
    }

}));

interface MovementFilterItemProps {
    movementFilter: MovementFilterInput;
}

export const MovementFilterItem: React.FC<MovementFilterItemProps> = (props) => {
    const classes = useMovementFilterItemStyles();
    const { movementFilter } = props;
    const { removeMovementFilter, setFilterTags } = useContext(TemplateEditorContext);

    const remove = useCallback(() => {
        removeMovementFilter(movementFilter);
    }, [movementFilter, removeMovementFilter]);

    const setTags = useCallback((tagIds: string[]) => {
        setFilterTags(movementFilter, tagIds);
    }, [movementFilter]);


    return (
        <Grid className={classes.container} container direction="row" alignItems="center">
            <Grid item xs={10}>
                <MovementFilterTagChooser
                    selectedTagIds={movementFilter.tagIds}
                    onChange={setTags}
                />
            </Grid>
            <Grid item xs={2} container justify="flex-end">
                <Button size="small" onClick={remove}>
                    <Delete color="error" fontSize="small" />
                </Button>
            </Grid>
        </Grid>
    );
};
