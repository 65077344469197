import React, { useContext } from "react";
import { makeStyles, TextField, Grid, InputLabel, FormControl, FormControlLabel, Input } from "@material-ui/core";
import { TemplateEditorContext } from "../Context";
import TextInput from "../../TextInput";
import TemplateTagChooser from "../../TemplateTagChooser";

const useTemplateMetadataStyles = makeStyles(({ spacing, palette, typography }) => ({
    container: {
        margin: spacing(2, 0),
        "& > :not(:first-child)":{
            marginTop:spacing(2)
        }
    }
}))

interface TemplateMetadataProps {

}

const TemplateMetadata: React.FC<TemplateMetadataProps> = (props) => {
    const classes = useTemplateMetadataStyles();
    const { template, setName, setDescription, setTagIds } = useContext(TemplateEditorContext);
    return (
        <Grid container className={classes.container} direction="column">
            <TextInput label="Name" value={template.name} onChange={setName} />
            <TextInput label="Description" value={template.description} onChange={setDescription} />
            <TemplateTagChooser selectedTagIds={template.tagIds} onChange={setTagIds}/>
        </Grid>
    );
};

export default TemplateMetadata;