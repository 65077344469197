import React from "react"
import { TagCollection } from './../../engine/TaggedItem';
import { Movement } from './../../generated/graphql';
import { Workout } from "../../api";
import { TemplateInput } from "../../generated/graphql";
import { AlertProps } from "@material-ui/lab";

export interface WorkoutGeneratorContextState {
    isLoading: boolean;
    isTemplateValid: boolean;
    workout?: Workout;
    message?: { severity: AlertProps["severity"], text: string }
    generate: () => void;
    getMovement: (id: string) => Movement | undefined;
    experienceLevel?: string;
    mergeTags: (...tags: TagCollection[]) => TagCollection;
}

const NOOP = () => { }

const DEFAULT_TEMPLATE: TemplateInput = {
    name: "Untitled Template",
    seriesCollection: [],
    tagIds: []
}

export const DEFAULT_CONTEXT: WorkoutGeneratorContextState = {
    isLoading: true,
    isTemplateValid: false,
    generate: NOOP,
    getMovement: () => undefined,
    mergeTags: (tags) => []
}



export const WorkoutGeneratorContext = React.createContext(DEFAULT_CONTEXT);