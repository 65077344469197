import React, { useCallback, useState } from "react";
import { makeStyles, FormControl, InputLabel, Input } from "@material-ui/core";

const useTextInputStyles = makeStyles(({ spacing, palette, typography }) => ({

}))

interface TextInputProps {
    label?: string;
    value?: string | null;
    placeholder?: string;
    onChange?: (text: string) => void;
    changeOnBlur?: boolean;
}

const TextInput: React.FC<TextInputProps> = (props) => {
    const classes = useTextInputStyles();
    const {
        label,
        value,
        placeholder,
        onChange,
        changeOnBlur = false
    } = props;

    const [current, setCurrent] = useState(value);

    const handleChange = useCallback((evt: React.ChangeEvent<HTMLInputElement>) => {
        const newVal = evt.target.value;
        setCurrent(newVal);

        if (!changeOnBlur && onChange && newVal) {
            onChange(newVal)
        }
    }, [onChange, setCurrent]);

    const handleBlur = useCallback(() => {
        if (changeOnBlur && onChange && current) {
            onChange(current);        }

    }, [changeOnBlur, current, onChange])

    return (
        <FormControl>
            {label && <InputLabel>{label}</InputLabel>}
            <Input onChange={handleChange} onBlur={handleBlur} placeholder={placeholder || label} value={current} />
        </FormControl>
    );
};

export default TextInput;