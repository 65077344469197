/**
 * Creates a shuffled version of the input array
 *
 * @export
 * @template T
 * @param {T[]} array
 * @returns {T[]}
 */
export function shuffle<T = any>(array: T[]): T[] {
    const newArray = [...array];
    let currentIndex = newArray.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = newArray[currentIndex];
        newArray[currentIndex] = newArray[randomIndex];
        newArray[randomIndex] = temporaryValue;
    }

    return newArray;
}


/**
 * Groups items in an array by a particular key
 *
 * @export
 * @template T
 * @template K
 * @param {T[]} items
 * @param {(item: T) => K} keySelector
 * @returns {Map<K, T[]>}
 */
export function groupBy<T, K>(items: T[], keySelector: (item: T) => K, itemSort?: (a: T, b: T) => number): Map<K, T[]> {
    const map = new Map<K, T[]>();

    items.forEach(item => {
        const key = keySelector(item);
        if (!map.has(key)) {
            map.set(key, []);
        }
        map.get(key)!.push(item);
    });

    if (itemSort) {
        return new Map(
            Array.from(map.entries())
                .map(([key, items]) => (
                    [key, items.sort(itemSort)]
                ))
        );
    }

    return map;
}


/**
 * Converts an array to a Map
 *
 * @export
 * @template T
 * @template K
 * @param {T[]} items
 * @param {(item: T) => K} keySelector
 * @returns {Map<K, T>}
 */
export function toMap<T, K>(items: T[], keySelector: (item: T) => K): Map<K, T> {
    const map = new Map<K, T>(
        items.map((item) => [
            keySelector(item),
            item
        ])
    );

    return map;
}

export function pickOneRandomly<T>(items: T[]): T {
    const randomIndex = Math.round(Math.random() * (items.length - 1));
    return items[randomIndex];
}


/**
 * Filters null or undefined items from array
 *
 * @export
 * @template T
 * @param {((T | undefined | null)[])} items
 * @returns {T[]}
 */
export function filterNils<T>(items: (T | undefined | null)[]): T[] {
    return items.filter(t => t !== undefined && t !== null) as T[];
}