import React, { useContext } from "react";
import { makeStyles, Grid, Typography, Button, Paper } from "@material-ui/core";
import { TemplateEditorContext } from "../Context";
import MovementSeriesItem from "./MovementSeriesItem";

const useMovementSeriesListStyles = makeStyles(({ spacing, palette, typography }) => ({
    container:{
        
    }
}))

interface MovementSeriesListProps {

}

const MovementSeriesList: React.FC<MovementSeriesListProps> = (props) => {
    const classes = useMovementSeriesListStyles();
    const { template, addSeries } = useContext(TemplateEditorContext);
    const { seriesCollection: series } = template;
    return (
        <Grid container direction="column" className={classes.container} spacing={1}>
            <Grid item xs={12}>
                <Button variant="contained" color="primary" onClick={() => addSeries(series.length)}>Add Series</Button>
            </Grid>
            {series.length > 0 ?
                series.map((movementSeries, index) => (
                    <Grid item xs={12} key={movementSeries.name} >
                        <MovementSeriesItem series={movementSeries} />
                    </Grid>
                ))
                :
                (
                    <Grid item xs={12}>
                        <Typography variant="h6">No Series</Typography>
                    </Grid >
                )
            }
        </Grid >
    );
};

export default MovementSeriesList;