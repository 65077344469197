import React, { useContext, useCallback, useState, useMemo, useEffect } from "react";
import { makeStyles, Grid, Button, Typography, Box } from "@material-ui/core";
import { TemplateEditorContext } from "../Context";
import { useMovementListQuery, useSystemDataQuery } from "../../../generated/graphql";
import { Engine } from "../../../engine";
import { Workout } from "../../../api/Workout";
import WorkoutGenerator from "../../WorkoutGenerator";

const useWorkoutPreviewStyles = makeStyles(({ spacing, palette, typography }) => ({
    orderedList: {
        padding: 0,
        margin: 0,
        "& li": {
            margin: spacing(0, 2.5)
        }
    },
    seriesTitle: {
        margin: spacing(2, 0, 1, 0),
        padding: 0
    },
    exercise: {
        fontWeight: typography.fontWeightBold
    }
}))

interface WorkoutPreviewProps {
    showJson?: boolean;
    experienceLevel?:string;
}

const WorkoutPreview: React.FC<WorkoutPreviewProps> = (props) => {
    const classes = useWorkoutPreviewStyles();
    const { template } = useContext(TemplateEditorContext);
    const { data: { movements = [], volumes = [] } = {}, loading, error } = useSystemDataQuery();
    const { showJson = false, experienceLevel } = props;

    return (
        <Grid container>
            <WorkoutGenerator template={template} experienceLevel={experienceLevel} />
            {showJson && (
                <Grid item>
                    {JSON.stringify(template)}
                </Grid>
            )}
        </Grid>
    );
};

export default WorkoutPreview;