import { EngineIntensity } from './EngineIntensity';
import { EngineData } from './EngineData';
import { Workout, ExerciseSeries, Exercise } from "../api";
import { Movement, TemplateInput, MovementSeries, MovementSeriesInput, Volume } from "../generated/graphql";
import { EMPTY_EXERCISE } from "./constants";
import { EngineContext } from "./EngineContext";
import { EngineMovement } from "./EngineMovement";
import { IEngine } from "./IEngine";
import { TagCollection, TaggedItem } from './TaggedItem';
import { pickOneRandomly } from '../utils/array';

export class Engine implements IEngine {
    constructor(public data: EngineData<TaggedItem, EngineIntensity, EngineMovement>) {

    }

    mergeTags(...tags: TagCollection[]) {
        return this.data.mergeTags(...tags);
    }

    private generateExercise(move: EngineMovement | null): Exercise {
        if (!move) {
            return { ...EMPTY_EXERCISE };
        }

        return {
            ...EMPTY_EXERCISE,
            movementId: move.id,
        }
    }

    async generate(context: EngineContext): Promise<Workout> {
        const { template, experienceLevel } = context;

        // Movement repo is just a mutable version of the movements DB
        // providing some useful utility methods when generating exercises
        const usedMovementIds: string[] = [];

        const seriesCollection = template.seriesCollection.map((series) => {
            // Select volume                
            const intensity = this.data.findIntensities(this.mergeTags(template.tagIds, series.tagIds, [experienceLevel]));

            const exercises = series.movements.map((moveFilter) => {
                // pick movement 
                const availableMovements = this.data.findMovements(moveFilter.tagIds)
                    .filter(m => !usedMovementIds.includes(m.id)); // only unused movements
                const move = pickOneRandomly(availableMovements);
                if (move) {
                    usedMovementIds.push(move.id);
                }
                const exercise = this.generateExercise(move);
                return {
                    ...exercise,
                    id: moveFilter.id
                }
            });

            const newSeries: ExerciseSeries = {
                name: series.name,
                type: series.type,
                exercises,
                tagIds: series.tagIds
            };
            return newSeries;
        });

        const workout: Workout = {
            tagIds: template.tagIds,
            seriesCollection
        }

        return workout
    }
}

