import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core";
import { ToggleButtonGroup, ToggleButton, ToggleButtonGroupProps } from "@material-ui/lab";

const useToggleStyles = makeStyles(({ spacing, palette, typography }) => ({

}))

type ValueLabel = { value: string, label?: string }

export interface ToggleProps extends Omit<ToggleButtonGroupProps, "onChange" | "value"> {
    options: ValueLabel[];
    disabledOptions?: string[];
    value?: string;
    onChange?: (value: string) => void;
}

const Toggle: React.FC<ToggleProps> = (props) => {
    const {
        options,
        disabledOptions = [],
        onChange,
        value = "",
        size = "small",
        ...restProps
    } = props;
    const classes = useToggleStyles();

    const handleChange = useCallback((ev, val: string) => {
        if (onChange) {
            onChange(val);
        }
    }, [onChange]);

    return (
        <ToggleButtonGroup
            onChange={handleChange}
            value={value}
            size={size}
            exclusive
            {...restProps}
        >
            {options.map(({ value, label }) => (
                <ToggleButton
                    size={size}
                    key={value}
                    disabled={disabledOptions.includes(value)}
                    value={value}>
                    {label || value}
                </ToggleButton>
            ))}
        </ToggleButtonGroup>
    );
};

export default Toggle;