import React from "react";
import { makeStyles, Typography, Grid, Card, CardHeader, Link, IconButton } from "@material-ui/core";
import { useTemplateListQuery } from "../../generated/graphql";
import Loader from "../Loader";
import { Edit } from "@material-ui/icons";
import { Link as RouterLink, routes } from "../../routing";

const useTemplateListStyles = makeStyles(({ spacing, palette, typography }) => ({
    container: {
        "& > :not(:first-child)": {
            marginTop: spacing(1)
        }
    },
    templateCard: {

    }
}))

interface TemplateListProps {

}

const TemplateList: React.FC<TemplateListProps> = (props) => {
    const classes = useTemplateListStyles();
    const { data, loading, error } = useTemplateListQuery();
    return (
        <Loader isLoading={loading}>
            <Grid className={classes.container} container direction="column" alignContent="stretch">
                {!data?.templates && (
                    <Typography variant="h5">No Templates</Typography>
                )}
                {data?.templates.map(template => (
                    <Card
                        className={classes.templateCard}
                        key={`${template.id}`}
                    >
                        <CardHeader
                            title={template.name}
                            subheader={template.description}
                            action={
                                <>
                                    {template.id && <Link component={RouterLink} to={routes.template.id(template.id).edit}>
                                        <Edit/>
                                    </Link>}
                                </>
                            }
                        />
                    </Card>
                ))}
            </Grid>
        </Loader>
    );
};

export default TemplateList;