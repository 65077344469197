import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core";
import Toggle, { ToggleProps } from "../Toggle";
import { Tag } from "../../generated/graphql";

const useTagToggleStyles = makeStyles(({ spacing, palette, typography }) => ({

}))

export interface TagToggleProps extends Omit<ToggleProps, "options"> {
    tags: Pick<Tag, "id" | "name">[];
}

const TagToggle: React.FC<TagToggleProps> = (props) => {
    const classes = useTagToggleStyles();
    const { tags = [], ...rest } = props;

    const options = useMemo(() => {
        return tags.map(({ id, name }) => ({ value: id, label: name }))
    }, [tags]);

    return (
        <Toggle options={options} {...rest} />
    );
};

export default TagToggle;