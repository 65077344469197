import React, { useContext, useEffect, useMemo } from "react";
import { makeStyles } from "@material-ui/core";
import TagToggle, { TagToggleProps } from "../TagToggle";
import { SystemContext } from "../../system";
import { TAG_CATEGORY } from "../../constants";
import { filterNils } from "../../utils/array";

const useExperienceSelectorStyles = makeStyles(({ spacing, palette, typography }) => ({

}))

interface ExperienceSelectorProps extends Omit<TagToggleProps, "tags"> {
    availableTags?: string[];
}

const ExperienceSelector: React.FC<ExperienceSelectorProps> = (props) => {
    const classes = useExperienceSelectorStyles();
    const { value, onChange, availableTags = [], ...rest } = props;
    const { tagCategories, tagMap, loading } = useContext(SystemContext);

    const [options, disabledOptions] = useMemo(() => {
        const { tagIds = [] } = tagCategories.find(cat => cat.name === TAG_CATEGORY["Experience Level"]) || {};
        const levels = filterNils(tagIds.map(tag => tagMap?.get(tag)));

        const tagMatches = availableTags.filter(inputTag => {
            return !!levels.find(tag => tag.id === inputTag)
        });

        const unavailable = tagIds.filter((tag) => {
            return tagMatches.length > 0 && !tagMatches.includes(tag)
        })

        return [levels, unavailable];
    }, [tagCategories, availableTags, tagMap]);

    useEffect(() => {
        if (!value && options && options.length > 0 && onChange) {
            const first = options.find(level => !disabledOptions.includes(level.id));
            if (first) {
                onChange(first?.id);
            }
        }
    }, [value, onChange, options, disabledOptions])

    return (
        loading ?
            (
                null
            ) :
            (
                <TagToggle
                    {...rest}
                    value={value}
                    tags={options}
                    disabledOptions={disabledOptions}
                    onChange={onChange}
                />
            )
    );
};

export default ExperienceSelector;