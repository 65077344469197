import React, { useMemo, useCallback, ChangeEvent, useEffect } from "react";
import { makeStyles, Slider, withStyles, SliderProps } from "@material-ui/core";
import { useExperienceTagsQuery } from "../../generated/graphql";
import Loader from "../Loader";

const StyledSlider = withStyles({
    root: {
        height: 8,
        marginRight:20,
        marginLeft:20
    },
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        marginTop: -8,
        marginLeft: -12,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    track: {
        height: 8,
        borderRadius: 4,
    },
    rail: {
        height: 8,
        borderRadius: 4,
    },
    mark: {
        height: 8,
        width: 3
    }
})(Slider);


const useExperienceSliderStyles = makeStyles(({ spacing, palette, typography }) => ({

}))

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

interface ExperienceSliderProps extends Omit<SliderProps, "onChange" | "value"> {
    /**
     * The id of the experience level tag
     *
     * @type {string}
     * @memberof ExperienceSliderProps
     */
    value?: string;
    onChange?: (levelId: string) => void;
}

const ExperienceSlider: React.FC<ExperienceSliderProps> = (props) => {
    const { onChange, value, ...restProps } = props;
    const classes = useExperienceSliderStyles();
    const { data, loading, error } = useExperienceTagsQuery();

    const levels = useMemo(() => {
        if (!data || !data.experience || data.experience.length === 0) {
            return [];
        }
        const [experience] = data.experience;
        return experience.levels.map((level, index) => ({
            value: index,
            label: level.name,
            id: level.id
        }));
    }, [data]);

    const handleChange = useCallback((evt: ChangeEvent<{}>, value: number | number[]) => {
        if (onChange) {
            const index = Array.isArray(value) ? value[0] : value;
            const { id } = levels[index];
            onChange(id);
        }
    }, [onChange, levels])

    const currentValue = useMemo(() => {
        if (!levels) {
            return undefined;
        }
        return Math.max(levels.findIndex(l => l.id === value),0);
    }, [levels, value]);

    useEffect(()=>{
        if(levels.length > 0){
            if(onChange){
                onChange(levels[0].id);
            }
        }
    },[levels]);

    return (
        <Loader isLoading={loading}>
            <StyledSlider
                {...restProps}
                value={currentValue}
                onChange={handleChange}
                min={0}
                max={levels.length - 1}
                marks={levels.map(({ value, label }) => ({
                    value,
                    label
                }))}
            />
        </Loader>
    );
};

export default ExperienceSlider;