import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import Editor from "../../components/Editor";

const useEditorScreenStyles = makeStyles(({ spacing, palette, typography }) => ({

}))

interface EditorScreenProps {

}

const EditorScreen: React.FC<EditorScreenProps> = (props) => {


    return (<>
        <Typography variant="h4">Workout Editor</Typography>
        <Editor />
    </>
    );
};

export default EditorScreen;