import React, { useState, useEffect, useCallback, useMemo, useContext } from 'react';
import { WorkoutGeneratorContext, WorkoutGeneratorContextState } from './WorkoutGeneratorContext';
import { Workout } from '../../api';
import { TemplateInput } from '../../generated/graphql';
import { SystemContext } from '../../system';
import { TagCollection } from '../../engine/TaggedItem';

interface WorkoutGeneratorContextProviderProps {
    template?: TemplateInput;
    experienceLevel?: string;
}
// todo: use guid or something for this
let recordIdNum = 0;

const WorkoutGeneratorContextProvider: React.SFC<WorkoutGeneratorContextProviderProps> = (props) => {
    const { children, template, experienceLevel } = props;
    const { loading: isLoading, engine, movements, movementMap } = useContext(SystemContext);

    const [workout, setWorkout] = useState<Workout | undefined>(undefined);
    const [message, setMessage] = useState<WorkoutGeneratorContextState["message"] | undefined>(undefined);

    const isTemplateValid = useMemo(() => {
        return template
            && template.seriesCollection
            && template.seriesCollection.length > 0
            && template.seriesCollection.some((sc => sc.movements && sc.movements.length > 0))
    }, [template]);

    const generate = useCallback(async () => {
        if (engine && template && isTemplateValid) {
            try {
                const generatedWorkout = await engine.generate({ template, experienceLevel });
                setWorkout(generatedWorkout);
            } catch (err) {
                // todo: handle error
            }
        } else {
            setWorkout(undefined);
        }
    }, [engine, template, isTemplateValid, experienceLevel, setWorkout]);

    const mergeTags = useCallback((...tags: TagCollection[]) => {
        return engine?.mergeTags(...tags) || [];
    }, [engine]);

    useEffect(() => {
        if (!isLoading) {
            if (movements.length === 0) {
                setMessage({
                    severity: "warning",
                    text: "Couldn't fetch movement library"
                });
            }
        }
    }, [isLoading, movements, setMessage]);

    useEffect(() => {
        generate();
    }, [generate]);

    return (
        <WorkoutGeneratorContext.Provider
            value={
                {
                    generate,
                    workout,
                    isLoading,
                    isTemplateValid,
                    getMovement: (id) => movementMap?.get(id),
                    mergeTags,
                    experienceLevel
                } as WorkoutGeneratorContextState
            }>
            {children}
        </WorkoutGeneratorContext.Provider>
    );
};

export default WorkoutGeneratorContextProvider;