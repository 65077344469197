import React, { useMemo, useCallback } from "react";
import { makeStyles, TextField, TextFieldProps } from "@material-ui/core";
import { Autocomplete, AutocompleteProps } from "@material-ui/lab";
import { useTagChooserTagsQuery } from "../../generated/graphql";
import Loader from "../Loader";

const useTagChooserStyles = makeStyles(({ spacing, palette, typography }) => ({
    formControl: {
        margin: spacing(1),
        minWidth: 120,
        maxWidth: 400,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
}))

export interface TagChooserProps {
    categoryIds?: string[]
    selectedTagIds?: string[];
    onChange?: (tagIds: string[]) => void;
    label?: string;
    variant?:TextFieldProps["variant"]
}

const TagChooser: React.FC<TagChooserProps> = (props) => {
    const classes = useTagChooserStyles();
    const { data, loading, error } = useTagChooserTagsQuery();
    const { selectedTagIds = [], onChange, label, categoryIds = null, variant="standard" } = props;

    // Todo: Put these maps into the context to avoid always calculating them
    const tagMap = useMemo(() => {
        return new Map(
            data?.tagCategories
                .flatMap((tc) => tc?.tags)
                .filter(tag => !!tag)
                .map((tag) => [tag?.id as string, tag])
        );
    }, [data]);

    const tagCategoryMap = useMemo(() => {
        return new Map(
            data?.tagCategories
                .flatMap((tc) => (
                    tc!.tags.map((tag) => [tag?.id, tc])
                ))
        );
    }, [data]);

    const handleChange = useCallback((evt, value: any[]) => {
        if (value && onChange) {
            onChange(value);
        }
    }, [onChange]);

    const options = useMemo(() => {
        return data?.tagCategories
            .filter(cat => !!cat && (!categoryIds || categoryIds.includes(cat.id)))
            .sort((a, b) => a!.tags.length - b!.tags.length)
            .flatMap((category) => {
                return category!.tags.map((tag) => tag!.id)
            }) || []
    }, [data, categoryIds]);

    return (
        <Loader delay={0} isLoading={loading}>
            <Autocomplete
                className={classes.formControl}
                fullWidth
                multiple
                options={options}
                groupBy={(option) => tagCategoryMap.get(option)?.name || "Unnamed Category"}
                getOptionLabel={(option) => tagMap.get(option)?.name || "Unnamed Tag"}
                defaultValue={[]}
                value={selectedTagIds}
                autoComplete={true}
                autoHighlight={true}
                onChange={handleChange}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant={variant as any} 
                        label={label}
                    />
                )}
            />
        </Loader>
    )
}

export default TagChooser;