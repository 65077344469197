import React, { useState, useEffect, useCallback, useMemo } from 'react';
import SystemContext, { SystemContextState, DEFAULT_CONTEXT } from './Context';
import { useSystemDataQuery } from '../generated/graphql';
import { EngineData } from '../engine/EngineData';
import { Engine } from '../engine';
import { toMap } from '../utils/array';


interface SystemContextProviderProps {

}

const SystemContextProvider: React.SFC<SystemContextProviderProps> = ({ children }) => {
    const { data, loading, error } = useSystemDataQuery()
    const [contextState, setContextState] = useState<SystemContextState>(DEFAULT_CONTEXT);

    const engineData = useMemo(() => {
        const { intensities, tagCategories, movements } = data || {};
        return new EngineData({
            categories: tagCategories,
            movements,
            intensities
        });
    }, [data]);


    // Update state based on graphql query
    useEffect(() => {
        const { movements, tags, tagCategories, volumes, intensities } = data || {};
        setContextState((currentState) => (
            {
                ...currentState,
                loading,
                error,
                movements: movements || currentState.movements,
                movementMap: toMap(movements || currentState.movements, (item) => item.id),
                tagCategories: tagCategories || currentState.tagCategories,
                tags: tags || currentState.tags,
                tagMap: toMap(tags || currentState.tags, (item) => item.id),
                volumes: volumes || currentState.volumes,
                intensities: intensities || currentState.intensities,
                engine: new Engine(engineData)
            }
        ))
    }, [data, engineData, loading, error]);



    return (
        <SystemContext.Provider value={contextState}>
            {children}
        </SystemContext.Provider>
    );
};

export default SystemContextProvider;