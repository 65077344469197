import React, { useState, useEffect, useCallback, useMemo, useContext } from 'react';
import { TemplateEditorContext, TemplateEditorContextState, DEFAULT_CONTEXT } from './Context';
import { MovementSeries, MovementFilter, MovementSeriesType } from '../../api';
import { TemplateInput, SaveTemplateDocument, MovementSeriesInput, MovementFilterInput } from '../../generated/graphql';
import { useMutation } from '@apollo/client';
import { validate as isUUID, v4 as uuidV4 } from "uuid";

function ensureUUID(id: string) {
    if (isUUID(id)) {
        return id;
    } else {
        return uuidV4();
    }
}

interface TemplateEditorContextProviderProps {
    template?: TemplateInput;
}


/**
 * Ensures each entity in template has UUID
 *
 * @param {TemplateInput} [template]
 * @returns {(TemplateInput | undefined)}
 */
function ensureTemplateIds(template: TemplateInput): TemplateInput {

    const { seriesCollection = [] } = template || {};
    const newSeriesCollection = seriesCollection.map(({ id: seriesId, movements, ...restSeries }) => {
        return {
            ...restSeries,
            id: ensureUUID(seriesId),
            movements: movements.map(({ id: moveId, ...restMove }) => ({
                id: ensureUUID(moveId),
                ...restMove
            }))
        } as MovementSeriesInput;
    });

    return {
        ...template,
        seriesCollection: newSeriesCollection
    }
}

const TemplateEditorContextProvider: React.FC<TemplateEditorContextProviderProps> = ({ children, template = { ...DEFAULT_CONTEXT.template } }) => {
    const inputTemplate = useMemo(() => {
        return ensureTemplateIds(template);
    }, [template]);
    const [currentTemplate, setCurrentTemplate] = useState<TemplateInput>(inputTemplate);

    const [saveTemplateMutation, { loading: isSaving }] = useMutation<TemplateInput, { template: TemplateInput }>(SaveTemplateDocument);

    const findMovementFilter = useCallback((currentTemplate: TemplateInput, filter: MovementFilterInput) => {
        const { seriesCollection } = currentTemplate;
        return seriesCollection.map((series, index) => (
            {
                series,
                seriesIndex: index,
                filterIndex: series.movements.indexOf(filter)
            }
        )).find(({ filterIndex }) => filterIndex >= 0);
    }, [])

    const addSeries = useCallback((index: number) => {
        setCurrentTemplate((current) => {
            const { seriesCollection } = current;
            const newSeriesCollection = [...seriesCollection];
            newSeriesCollection.splice(index, 0, {
                id: uuidV4(),
                name: `Series ${String.fromCharCode(65 + seriesCollection.length)}`,
                type: MovementSeriesType.Regular,
                movements: [],
                tagIds: []
            });

            return {
                ...current,
                seriesCollection: newSeriesCollection
            };
        });
    }, [setCurrentTemplate]);

    const removeSeries = useCallback((seriesToRemove: MovementSeriesInput) => {
        setCurrentTemplate((current) => {
            const { seriesCollection } = current;
            const newSeriesCollection = [...seriesCollection];
            const index = newSeriesCollection.indexOf(seriesToRemove);
            if (index >= 0) {
                newSeriesCollection.splice(index, 1);
            }

            return {
                ...current,
                seriesCollection: newSeriesCollection
            };
        });
    }, [setCurrentTemplate]);

    const updateSeries = useCallback((seriesToUpdate: MovementSeriesInput, updates: Partial<MovementSeriesInput>) => {
        setCurrentTemplate((current) => {
            const { seriesCollection } = current;
            const newSeriesCollection = [...seriesCollection];
            const index = newSeriesCollection.indexOf(seriesToUpdate);
            if (index >= 0) {
                newSeriesCollection[index] = {
                    ...seriesToUpdate,
                    ...updates
                };
            }

            return {
                ...current,
                seriesCollection: newSeriesCollection
            };
        });
    }, [setCurrentTemplate]);

    const addMovementFilter = useCallback((series: MovementSeriesInput, filterIndex: number) => {
        setCurrentTemplate((current) => {
            const { seriesCollection } = current;
            const newSeriesCollection = [...seriesCollection];
            const index = newSeriesCollection.indexOf(series);
            if (index >= 0) {
                const newMovements = [...series.movements];
                newMovements.splice(filterIndex, 0, {
                    id: uuidV4(),
                    tagIds: []
                });

                newSeriesCollection[index] = {
                    ...series,
                    movements: newMovements
                };

                return {
                    ...current,
                    seriesCollection: newSeriesCollection
                };
            }

            return current;
        });


    }, [setCurrentTemplate]);

    const removeMovementFilter = useCallback((filter: MovementFilterInput) => {
        setCurrentTemplate((current) => {
            const { seriesCollection } = current;
            const newSeriesCollection = [...seriesCollection];
            const seriesAndFilterIndex = findMovementFilter(current, filter);

            if (seriesAndFilterIndex) {
                const { series, filterIndex, seriesIndex } = seriesAndFilterIndex;
                const newMovements = [...series.movements];

                // Remove the filter
                newMovements.splice(filterIndex, 1);

                // Update the seriesCollection
                newSeriesCollection[seriesIndex] = {
                    ...series,
                    movements: newMovements
                };

                return {
                    ...current,
                    seriesCollection: newSeriesCollection
                };
            }

            return current;
        });
    }, [setCurrentTemplate, findMovementFilter]);

    const setFilterTags = useCallback((filter: MovementFilterInput, tagIds: string[]) => {
        setCurrentTemplate((current) => {
            const seriesAndFilterIndex = findMovementFilter(current, filter);
            if (!seriesAndFilterIndex) {
                return current;
            }

            const newFilter = { ...filter, tagIds: [...tagIds] };

            const newSeriesCollection = [...current.seriesCollection];
            const { series, seriesIndex, filterIndex } = seriesAndFilterIndex;

            const newSeriesMovements = [...series.movements];
            newSeriesMovements[filterIndex] = newFilter;

            newSeriesCollection[seriesIndex] = { ...series, movements: newSeriesMovements };

            return {
                ...current,
                seriesCollection: newSeriesCollection
            }
        });

    }, [setCurrentTemplate, findMovementFilter]);

    const saveTemplate = useCallback(async () => {
        try {
            const omitTypename = (key: string, value: any) => (key === '__typename' ? undefined : value);
            const cleanedTemplate = JSON.parse(JSON.stringify(currentTemplate), omitTypename);
            await saveTemplateMutation({ variables: { template: cleanedTemplate } });
        } catch (e) {
            console.log("SAVE MUTATION ERROR", e);
        }
    }, [saveTemplateMutation, currentTemplate]);

    const setName = useCallback((name: string) => {
        setCurrentTemplate(current => ({ ...current, name }));
    }, [setCurrentTemplate]);

    const setDescription = useCallback((description: string) => {
        setCurrentTemplate(current => ({ ...current, description }));
    }, [setCurrentTemplate]);

    const setTagIds = useCallback((ids: string[]) => {
        setCurrentTemplate(current => ({ ...current, tagIds: [...ids] }));
    }, [setCurrentTemplate]);

    return (
        <TemplateEditorContext.Provider
            value={
                {
                    template: currentTemplate,
                    setName,
                    setDescription,
                    setTagIds,
                    addSeries,
                    removeSeries,
                    updateSeries,
                    addMovementFilter,
                    removeMovementFilter,
                    setFilterTags,
                    saveTemplate,
                    isSaving
                } as TemplateEditorContextState
            }>
            {children}
        </TemplateEditorContext.Provider>
    );
};

export default TemplateEditorContextProvider;