import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Card, CardHeader, fade, Grid, makeStyles, Typography } from "@material-ui/core";
import ExperienceSlider from "../ExperienceSlider";
import TagToggle from "../TagToggle";
import { TemplateInput, useTemplateFilterQuery } from "../../generated/graphql";
import Loader from "../Loader";
import { groupBy } from "../../utils/array";
import { CATEGORY_EXPERIENCE_LEVEL } from "../../constants";
import WorkoutGenerator from "../WorkoutGenerator";
import classNames from "classnames";
import { isDevelopment } from "../../environment";


const useTemplateFilterStyles = makeStyles(({ spacing, palette, typography }) => ({
    templateCard: {
        width: "100%",
        "&.selected": {
            background: fade(palette.secondary.main, .2)
        },
        "&:hover": {
            background: fade(palette.secondary.main, .1),
            cursor: "pointer"
        }
    },
    slider: {
        width: "80%"
    },
    templateSelectionContainer: {
        "& > :not(:first-child)": {
            marginTop: spacing(1)
        }
    },
    tagToggleRow: {
        "& > :not(:first-child)": {
            marginTop: spacing(0.5)
        }
    },
    tagToggleContainer: {
        overflowX: "auto",
        // "&::-webkit-scrollbar":{
        //     display:"none"
        // }
    },
    templateContainer: {
        overflowY: "auto",
    },
    workout: {
        padding: spacing(2)
    }
}))

interface TemplateFilterProps {

}

const TemplateFilter: React.FC<TemplateFilterProps> = (props) => {
    const classes = useTemplateFilterStyles();
    const { data, loading, error } = useTemplateFilterQuery();

    const [selectedTemplate, setSelectedTemplate] = useState<TemplateInput | undefined>();

    const [selectedTags, setSelectedTags] = useState<{ [key: string]: string }>({});

    const setTagValue = useCallback((tagCategory: string, value: string) => {
        setSelectedTags((currentSelected) => {
            return {
                ...currentSelected,
                [tagCategory]: value
            }
        });
    }, [setSelectedTags]);

    const setExperienceLevel = useCallback((val: string) => {
        setTagValue(CATEGORY_EXPERIENCE_LEVEL, val)
    }, [setTagValue]);

    const experienceLevel = useMemo(() => (
        selectedTags[CATEGORY_EXPERIENCE_LEVEL]
    ), [selectedTags]);

    const categorizedTags = useMemo(() => {
        const { templates = [] } = data || {};

        const uniqueTags = new Map(
            templates.flatMap(template => template.tags.map(tag => ([tag.id, tag])))
        );

        // todo: safer access of category
        const groupedTags = groupBy(Array.from(uniqueTags.values()), (tag => tag.category[0].id))

        return Array.from(groupedTags)
            .map(([, tags]) => ({
                category: tags[0].category[0],
                tags,
            }))
            .filter(({ category }) => category.name !== CATEGORY_EXPERIENCE_LEVEL);
    }, [data]);

    const filteredTemplates = useMemo(() => {
        const { templates = [] } = data || {};

        const selectedTagIds = Object.keys(selectedTags)
            .map(k => selectedTags[k])
            .filter(val => !!val);

        return templates.filter(template => selectedTagIds.every(tid => template.tagIds.includes(tid))) as TemplateInput[];

    }, [data, selectedTags]);

    const unavailableTags = useMemo(() => {
        const available = new Set(
            filteredTemplates.flatMap(template => template.tagIds)
        );

        const unavailable = new Set(
            categorizedTags
                .flatMap(({ tags }) => tags.map(t => t.id))
                .filter(id => !available.has(id))
        );

        return Array.from(unavailable);
    }, [filteredTemplates, categorizedTags]);

    useEffect(() => {
        if (isDevelopment() && !selectedTemplate && filteredTemplates.length > 0) {
            setSelectedTemplate(filteredTemplates[0]);
        }
    }, [filteredTemplates, selectedTemplate, setSelectedTemplate])

    // Unselects unavailable tags (happens when switching experience level)
    useEffect(() => {
        setSelectedTags((currentSelected) => {
            const newSelected = { ...currentSelected }
            let shouldUpdate = false;
            Object.keys(newSelected).forEach(key => {
                const val = newSelected[key];
                if (unavailableTags.includes(val)) {
                    delete newSelected[key];
                    shouldUpdate = true;
                }
            });

            // Only update if an item has been deselected
            return shouldUpdate ? newSelected : currentSelected;
        });
    }, [unavailableTags, setSelectedTags]);

    useEffect(() => {
        if (selectedTemplate && !filteredTemplates.includes(selectedTemplate)) {
            setSelectedTemplate(undefined);
        }
    }, [filteredTemplates, setSelectedTemplate, selectedTemplate])

    return (
        <Loader isLoading={loading}>
            <Grid container direction="row">
                <Grid item xs={12} sm={6} container className={classes.templateSelectionContainer}>
                    <Grid container item xs={12} justify="center">
                        <ExperienceSlider className={classes.slider} value={experienceLevel} onChange={setExperienceLevel} />
                    </Grid>
                    {categorizedTags.map(({ category, tags, }) => (
                        <Grid key={category.id} item container xs={12} className={classes.tagToggleRow}>
                            <Grid item xs={12}>
                                <label>{category.name}</label>
                            </Grid>
                            <Grid xs={12} item className={classes.tagToggleContainer}>
                                <TagToggle
                                    value={selectedTags[category.id]}
                                    onChange={(val) => setTagValue(category.id, val)}
                                    tags={tags}
                                    disabledOptions={unavailableTags}
                                />
                            </Grid>
                        </Grid>
                    ))}
                    <Grid item xs={12} className={classes.templateContainer}>
                        {filteredTemplates.map((template, index) => (
                            <Card key={template.id || index} className={classNames(classes.templateCard, { selected: template === selectedTemplate })}>
                                <CardHeader onClick={() => setSelectedTemplate(template)}
                                    title={template.name}
                                    subheader={template.description}
                                />
                            </Card>
                        ))}
                    </Grid>
                </Grid>
                <Grid item sm={6} className={classes.workout}>
                    {selectedTemplate ?
                        (
                            <WorkoutGenerator experienceLevel={selectedTags[CATEGORY_EXPERIENCE_LEVEL]} template={selectedTemplate} />
                        )
                        :
                        (
                            <Typography align="center" variant="h5">Select a template</Typography>
                        )
                    }
                </Grid>
            </Grid>
        </Loader>
    );
};

export default TemplateFilter;