import React from 'react';
import { makeStyles, Grid } from "@material-ui/core";
import Header from './components/Header';
import { Container } from '@material-ui/core';
import HomeScreen from './screens/home';
import Footer from './components/Footer';
import { Router, Route, Switch, Redirect } from './routing';
import routes from './routing/routes';
import { TemplateScreenRoot } from './screens/templates';
import EditorScreen from './screens/editor';


const useAppStyles = makeStyles(({ spacing, palette, typography }) => ({
  app: {
    height: "100%"
  },
  content: {
    flex: 1,
    overflow: "auto",
    paddingTop: spacing(2)
  }
}))

interface AppProps {

}

const App: React.SFC<AppProps> = (props) => {
  const classes = useAppStyles();
  return (
    <Router>
      <Grid container direction="column" className={classes.app}>
        <Grid item >
          <Header />
        </Grid>
        <Container className={classes.content}>
          <Switch>
            <Route path={routes.template.path} component={TemplateScreenRoot} />
            <Route path={routes.editor.path} component={EditorScreen} />
            <Route exact={true} path={routes.home.path} component={HomeScreen} />
            <Route path="*">
              <Redirect to={routes.home.path} />
            </Route>
          </Switch>
        </Container>
        <Grid item>
          <Footer />
        </Grid>
      </Grid>
    </Router>
  );
};

export default App;
