import React from "react";
import { makeStyles, Grid, Typography } from "@material-ui/core";
import { MovementFilter } from "../../../api";
import { MovementFilterItem } from "./MovementFilterItem";
import { MovementFilterInput } from "../../../generated/graphql";

const useMovementFilterListStyles = makeStyles(({ spacing, palette, typography }) => ({
    filterContainer:{
        "& > :not(:first-child)":{
            marginTop:spacing(1)
        }
    }
}))

interface MovementFilterListProps {
    movements: MovementFilterInput[];
}

const MovementFilterList: React.FC<MovementFilterListProps> = (props) => {
    const classes = useMovementFilterListStyles();
    const { movements } = props;
    return (
        <Grid container>
            <Grid item xs={12} className={classes.filterContainer}>
                {movements.map((m,index) => (
                    <MovementFilterItem key={m.id} movementFilter={m} />
                ))}
            </Grid>
        </Grid>
    );
};

export default MovementFilterList;