function makePath(path: string) {
    return `${process.env.PUBLIC_URL}${path}`
}

const routes = {
    home: {
        path: makePath("/")
    },
    template: {
        path: makePath("/template"),
        new: makePath("/template/new"),
        id: (templateId: number | string = ":id") => ({
            details: makePath(`/template/${templateId}`),
            edit: makePath(`/template/${templateId}/edit`),
        }),
    },
    editor: {
        path: makePath("/editor")
    },
    // shoppingList: {
    //     path: makePath("/shopping"),
    //     evaluation: (evalNumber: number | string = ":evalNumber") => ({
    //         root: makePath(`/shopping/${evalNumber}`),
    //         buy: makePath(`/shopping/${evalNumber}/buy`),
    //         print: makePath(`/shopping/${evalNumber}/print`)
    //     })
    // },
    // ! Only for design / dev purposes
    __design: {
        path: makePath("/__design")
    }
}

export default routes;