import React from "react";
import { Switch, Route, routes } from "../../routing";
import TemplateDetails from "./Details";
import TemplateEdit from "./Edit";
import NewTemplate from "./New";
import TemplateListScreen from "./List";

interface TemplateScreenRootProps {

}

const templatePaths = routes.template.id()

export const TemplateScreenRoot: React.FC<TemplateScreenRootProps> = (props) => {
    return (
        <Switch>
            <Route path={routes.template.new} component={NewTemplate} />
            <Route path={templatePaths.edit} component={TemplateEdit} />
            <Route exact={true} path={templatePaths.details} component={TemplateDetails} />
            <Route exact={true} path={routes.template.path} component={TemplateListScreen} />
        </Switch>
    );
};

