import React from "react";
import { makeStyles, Box } from "@material-ui/core";

const useFooterStyles = makeStyles(({spacing, palette, typography})=>({
    root:{
        padding:spacing(2),
        background:palette.secondary.main,
        color:palette.getContrastText(palette.secondary.main),
        display:"flex",
        flexDirection:"row",
        justifyContent:"center"
    }
}))

interface FooterProps {

}

const Footer : React.SFC<FooterProps> = (props) => {
    const classes = useFooterStyles();
    return (
        <Box className={classes.root}>
            Boundless Trainer
        </Box>
    );
};

export default Footer;