import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";


const useLoaderStyles = makeStyles(({ spacing, palette, typography }) => ({

}))


const DefaultLoadingElement: React.SFC = () => (
    <Skeleton variant="text"/>
)

interface LoaderProps {
    isLoading: boolean;
    loadingElement?: React.ReactNode
    delay?: number;
}

interface LoaderStatic {
    Blank: React.ComponentType<LoaderProps>
}

const Loader: React.SFC<LoaderProps> & LoaderStatic = (props) => {
    // const classes = useLoaderStyles();
    const [showLoading, setShowLoading] = useState<boolean>(false);

    const {
        isLoading = false,
        loadingElement = <DefaultLoadingElement />,
        children,
        delay = 500
    } = props;

    // Delay to avoid flicker
    useEffect(() => {
        if (isLoading === true) {
            const id = setTimeout(() => {
                setShowLoading(true);
            }, delay);

            return () => clearTimeout(id);
        } else {
            setShowLoading(false);
        }
    }, [isLoading, setShowLoading, delay])

    return (
        <>
            {isLoading && showLoading && loadingElement}
            {!isLoading && children}
        </>
    );
};


Loader.Blank = (props) => (
    <Loader loadingElement={null} {...props} />
);

export default Loader;