import React, { useCallback, useEffect, useState } from 'react';
import MonacoEditor, { ChangeHandler } from 'react-monaco-editor';
import 'monaco-yaml/lib/esm/monaco.contribution';
import { languages } from 'monaco-editor/esm/vs/editor/editor.api';

// NOTE: This will give you all editor featues. If you would prefer to limit to only the editor
// features you want to use, import them each individually. See this example: (https://github.com/microsoft/monaco-editor-samples/blob/master/browser-esm-webpack-small/index.js#L1-L91)
import 'monaco-editor'


const { yaml } = (languages || {}) as any;

const Editor = () => {
  const [value, setValue] = useState(`version: "0.1"
work:
  name: Kitchen Sink
  description: Exercises all aspects of workout schema
  groupWork:
    completedBy: anyone
    parallel: false
  time:
    min: 15
    max: 60
    exact: 23
    timed: true
  effort:
    forTime: 60
    calories: 300
    distance: 233
    duration: 23
    heartrate: 134
    intensity: 80
    power: 300
    reps: 4
    weight:
      - body
      - 100
      - body
  repeat: 3
  rest: 0
  tags:
    - lower
    - quads
  items:
    - movement: burpee
    - movement:
        select:
          from:
            - pull up
            - chin ups
            - include:
              - Upper body
              - Back
            - exclude:
              - Pecs
    - rest: 12
    - work:
        effort:
          calories:
            - 100
            - 200
            - 300
        movement: Rowing
    `);
  useEffect(() => {
    yaml &&
      yaml.yamlDefaults.setDiagnosticsOptions({
        validate: true,
        enableSchemaRequest: true,
        hover: true,
        completion: true,
        format: true,
        schemas: [
          {
            uri: '/workout-schema.json', // id of the first schema
            fileMatch: ['*'], // associate with our model
          },
        ],
      });
  }, []);

  const handleChange = useCallback<ChangeHandler>((newValue, evt) => {
    setValue(newValue);
  }, [setValue])

  return (
    <MonacoEditor
      width="100%"
      height="calc(100% - 30px)"
      language="yaml"
      options={{
        lineNumbers: "off",
        autoIndent: "advanced",
      }}
      value={value}
      onChange={handleChange}
    />
  );
};

export default Editor;