import React from "react";
import { makeStyles } from "@material-ui/core";
import { useParams } from "../../routing";

const useTemplateDetailsStyles = makeStyles(({ spacing, palette, typography }) => ({

}))

interface TemplateDetailsProps {

}

const TemplateDetails: React.FC<TemplateDetailsProps> = (props) => {
    const classes = useTemplateDetailsStyles();
    const { id } = useParams<{ id: string }>();
    return (
        <div>Template Details {id}</div>
    );
};

export default TemplateDetails;