import { TagCollection, TaggedItem, TagType } from './TaggedItem';
import { filterNils, groupBy } from '../utils/array';



interface EngineDataInput<TCategories extends TaggedItem, TIntensity extends TaggedItem, TMovement extends TaggedItem> {
    categories?: TCategories[];
    intensities?: TIntensity[];
    movements?: TMovement[];
}

export class EngineData<TCategory extends TaggedItem, TIntensity extends TaggedItem, TMovement extends TaggedItem> {
    private categories: TCategory[];
    private intensities: TIntensity[];
    private movements: TMovement[];
    private tagCategoryLookup: Map<string, TCategory>;


    constructor(config: EngineDataInput<TCategory, TIntensity, TMovement> = {}) {
        const { categories = [], intensities = [], movements = [] } = config;
        this.categories = categories;
        this.intensities = intensities;
        this.movements = movements;


        this.tagCategoryLookup = new Map(this.categories
            .flatMap(cat => filterNils(cat.tagIds).map(tag => [tag, cat]))
        );
    }

    getCategory(tag: TagType) {
        if (!tag) {
            return undefined
        }
        return this.tagCategoryLookup.get(tag);
    }

    matchesTagFromEachCategory(item: TaggedItem, searchTags: TagType[]) {
        const groupedCats = groupBy(item.tagIds, (tag) => this.getCategory(tag));

        return Array.from(groupedCats.values()).every(catTags => {
            return filterNils(catTags).some(tag => searchTags.includes(tag))
        });
    }

    matchesTags(item: TaggedItem, searchTags: TagType[]) {
        return searchTags.every(tag => item.tagIds.includes(tag));
    }

    findIntensities(searchTags: TagType[]) {
        const items = this.intensities.filter(item => {
            return this.matchesTagFromEachCategory(item, searchTags)
        });
        return items;
    }

    findMovements(searchTags: TagType[]) {
        const items = this.movements.filter(item => this.matchesTags(item, searchTags));
        return items;
    }

    /**
     * Merges Tags together with latter collections taking precedence
     *
     * @param {...TagCollection[]} tags
     * @returns {TagCollection}
     * @memberof TagMerger
     */
    mergeTags(...tags: TagCollection[]): TagCollection {

        // For each collection of tags, group them by category into a Map
        const mergedTagGroups = tags.map(inputTags => {
            return groupBy(filterNils(inputTags), (tag) => this.tagCategoryLookup.get(tag))
        }).reduce((dict, group) => {
            // Reduce the collection of Maps to a single Map
            // replacing tag collections with higher precedence ones (i.e. second, third, ... input to method)
            Array.from(group.entries())
                .forEach(([cat, tags]) => {
                    dict.set(cat, tags);
                })
            return dict;
        }, new Map<TaggedItem | undefined, TagCollection>());

        // Flatten the tags within the map
        return Array.from(mergedTagGroups.values()).flatMap(tags => tags)
    }


}