import React, { useContext } from "react";
import { Box, Button, Grid, makeStyles, Typography } from "@material-ui/core";
import Loader from "../../Loader";
import { WorkoutGeneratorContext } from "../WorkoutGeneratorContext";
import { Alert } from "@material-ui/lab";
import IntensitySelector from "../../IntensitySelector";
import ExerciseDisplay from "./ExerciseDisplay";
import TagItem from "../../TagItem";

const useWorkoutGeneratorLayoutStyles = makeStyles(({ spacing, palette, typography }) => ({
    orderedList: {
        padding: 0,
        margin: 0,
        "& li": {
            margin: spacing(0, 2.5)
        },
        width: "100%"
    },
    seriesTitle: {
        margin: spacing(2, 0, 1, 0),
        padding: 0
    },
    exercise: {
        fontWeight: typography.fontWeightBold
    },
    exerciseRow: {
        width: "100%"
    },
}))

interface WorkoutGeneratorLayoutProps {

}

const WorkoutGeneratorLayout: React.FC<WorkoutGeneratorLayoutProps> = (props) => {
    const classes = useWorkoutGeneratorLayoutStyles();
    const { isLoading, isTemplateValid, message, workout, generate, getMovement, experienceLevel } = useContext(WorkoutGeneratorContext);
    return (
        <Loader isLoading={isLoading}>
            <Grid container justify="center">
                {!message && (
                    <Button variant="contained" disabled={!isTemplateValid} color="primary" onClick={generate}>Generate Workout</Button>
                )}

                {message && (
                    <Alert severity={message.severity}>{message.text}</Alert>
                )}

                {workout && (
                    <Grid item container>
                        <ol className={classes.orderedList}>
                            {workout.seriesCollection.map((series, index) => (
                                <Grid key={`${series.name}:${index}`} item container direction="column">
                                    <Box className={classes.seriesTitle}>
                                        <Typography variant="h5">{series.name}</Typography>
                                        <Typography variant="subtitle2">{series.type}</Typography>
                                    </Box>
                                    {series.exercises.map((exercise, index) => (
                                        <li className={classes.exercise} key={`${exercise.id}:${index}`}>
                                            <ExerciseDisplay exercise={exercise} seriesTags={series.tagIds} />
                                        </li>
                                    ))}
                                </Grid>
                            ))}
                        </ol>
                    </Grid>
                )}
            </Grid>
        </Loader>
    );
};

export default WorkoutGeneratorLayout;